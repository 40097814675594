import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { orderListApi } from "./../../Redux/orderListSlice";
import { LeftMenu } from "./LeftMenu";
export const Orders = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  const { orders, loading } = useSelector((state) => state.ordersList);
  console.log("auth");
  console.log(auth);
  if (auth == null) {
    navigate("/");
  }

  const user = JSON.parse(auth);
  const [user_id, setUserId] = useState(user.id);

  useEffect(() => {
    dispatch(orderListApi(user_id));
    // setBanners([results]);
  }, []);

  console.log(orders);
  return (
    <>
      <Helmet>
        <title>My Orders</title>
        <meta name="description" content="My Orders" />
      </Helmet>
      <div className="breadcrumbs">
        <div className="container">
          <div className="title h1 pb-2">My Orders</div>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item active">My Orders</li>
          </ol>
        </div>
      </div>
      <section className="account-details pt--60 pb--60">
        <div className="container">
          <h1 className="text-center mb-5">My Orders</h1>
          <div className="row">
            <div className="col-lg-3">
              <div className="account-sidebar">
                <LeftMenu active={3}></LeftMenu>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="account">
                <div className="title">Orders</div>
                {loading == true && <div className="text-center mt-5"><h4>Loading..</h4></div>}
                {loading == false && orders.length == 0 && (
                  <div className="text-center">
                    <img
                      src={window.location.origin + "/assets/images/cart.png"}
                      alt="cart"
                      width="70px"
                    />
                    <h5>No Order Found</h5>
                  </div>
                )}
                {loading == false && orders && (
                  <div className="orders">
                 
                    {orders.map((order, key) => {
                      return (
                        <div className="order-items" key={key}>
                          <div className="order-id">
                            <div className="row">
                              <div className="col-md-6">
                                <span className="">Order Id : </span>
                                <span>{order.order_no}</span>
                                <br></br>
                                Order Date : {order.booking_date}
                              </div>
                              <div className="col-md-6">
                                <span className="pull-right order-no">
                                  Order Status : {order.payment_status} <br />
                                  Payment Type : {order.payment_type} <br></br>
                                  Total Price : {order.total_amount}
                                </span>
                              </div>
                            </div>
                          </div>
                          {order.products && (
                            <div className="item-list">
                              {order.products.map((product, key) => {
                                return (
                                  <div
                                    className="order-item my-2 py-1"
                                    key={key}
                                  >
                                    <div className="row align-items-center m-0">
                                      <div className="col-lg-2 col-sm-2 col-3 text-center">
                                        <img
                                          src={product.product_image}
                                          alt={product.product_name}
                                        />
                                      </div>
                                      <div className="col-lg-6 col-md-6 col-sm-7 col-9">
                                        <h6 className="art-name">
                                          {product.product_name}
                                        </h6>
                                        <span>
                                          {product.product_size
                                            ? product.product_size + ", "
                                            : ""}
                                          {product.product_orientation
                                            ? product.product_orientation + ", "
                                            : ""}{" "}
                                          ,
                                          {product.product_medium
                                            ? ", " + product.product_medium
                                            : ""}
                                        </span>
                                      </div>
                                      <div className="col-lg-4 col-md-4 col-sm-7 col-9 text-end">
                                      {product.product_price}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
