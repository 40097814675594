import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { homeApi } from "../Redux/homeSlice";
// import Skeleton from 'react-loading-skeleton'
// import 'react-loading-skeleton/dist/skeleton.css'

export const Home = () => {
  //const [products] = useFetch("product");

  const dispatch = useDispatch();
  const { banners, products, artists, pressReleases, exhibitions, loading } =
    useSelector((state) => state.home);

  useEffect(() => {
    dispatch(homeApi());
  }, []);

  const options = {
    loop: true,
    responsiveClass: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    animateOut: "fadeOut",
    animateIn: "fadeIn",
    nav: false,
  };

  return (
    <>
      <Helmet>
        <title>Buy Paintings Online | Matti Sirvio Galleria</title>
        <meta
          name="description"
          content=">Buy Paintings Online | Matti Sirvio Galleria"
        />
      </Helmet>
      {loading == true && <Skeleton className="banner"></Skeleton>}
      {banners.length > 0 && (
        <OwlCarousel {...options} nav>
          {banners.map((post, key) => {
            return (
              <div className="slider-card" key={key}>
                <Link to={post.banner_link}>
                  <div
                    className="banner"
                    style={{
                      backgroundImage: `url(${post.image_url})`,
                      backgroundRepeat: "no-repeat",
                    }}
                  >
                    <div className="container row align-items-center">
                      <div className="m-auto text-center">
                        <div className="h1">{post.banner_title}</div>
                        <div className="p">{post.banner_description}</div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </OwlCarousel>
      )}

      <section className="arrivals pt--60">
        <div className="container">
          <h2 className="text-center title-element">ARTWORKS</h2>
          <div className="row">
            {products && (
              <>
                {products.map((product, key) => {
                  return (
                    <div className="col-lg-3 col-sm-4 col-6" key={key}>
                      <Link to={`/arts/${product.product_slug}`}>
                        <div className="arrival-box">
                          <div className="img">
                            <img
                              src={product.feature_image}
                              alt={product.product_name}
                            />
                          </div>
                          <div className="content">
                            <p className="text-center">
                              {product.product_name}
                            </p>
                            <div className="Price text-center">
                              {product.product_price}
                            </div>
                          </div>
                          {product.available == "Sold" && (
                            <div className="sold-out">Sold Out</div>
                          )}
                        </div>
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className="text-center pb-5">
            <Link className="view-btn" to={`/arts`}>
              View More <i className="fa fa-arrow-right ps-1" />
            </Link>
          </div>
        </div>
      </section>

      {exhibitions && (
        <section className="upcoming pt--60">
          <div className="container">
            <h2 className="text-center title-element">UPCOMING EXHIBITIONS</h2>
            <div className="row">
              {exhibitions.map((exhibition, key) => {
                return (
                  <div className="col-lg-4 col-sm-6 col-12" key={key}>
                    <Link to={"exhibitions/" + exhibition.exhibition_slug}>
                      <div className="upcoming-box">
                        <div className="img">
                          <img src={exhibition.exhibition_image} alt="" />
                        </div>
                        <div className="content">
                          <p className=" text-center">
                            {exhibition.exhibition_title}
                          </p>
                          <div className="date text-center">
                            {exhibition.exhibition_start_date} -
                            {exhibition.exhibition_end_date}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="text-center my-5">
              <Link to={"/exhibitions"} className="view-btn">
                View More <i className="fa fa-arrow-right ps-1" />
              </Link>
            </div>
          </div>
        </section>
      )}
      {artists && (
        <section className="featured-artist pt--60 pb--60">
          <div className="container">
            <h2 className="text-center title-element">FEATURED ARTIST</h2>
            <div className="row">
              {artists.map((artist, key) => {
                return (
                  <div className="artist-box" key={key}>
                    <div className="artist">
                      <Link to={"/artist/" + artist.artist_slug}>
                        <img
                          src={artist.profile_image}
                          alt={artist.first_name + " " + artist.last_name}
                        />
                      </Link>
                      <Link to={"/artist/" + artist.artist_slug}>
                        <div className="content">
                          <div className="h6">
                            {artist.first_name} {artist.last_name}
                          </div>
                          <div className="art-count text-muted">
                            {artist.total_product} Art
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}

              <div className="text-center my-5">
                <Link to={"/artist"} className="view-btn">
                  View More <i className="fa fa-arrow-right ps-1" />
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}

      {pressReleases && (
        <section className="press pt--60 pb--60">
          <div className="container">
            <h2 className="text-center title-element">PRESS RELEASE</h2>
            <div className="row">
              {pressReleases.map((press, key) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={key}>
                    <Link to={press.article_pdf} target="_blank">
                      <div className="press-box">
                        <div className="img">
                          <img src={press.article_image} alt="news" />
                        </div>
                        <div className="content">
                          <p className="">{press.article_title}</p>
                          <div className="date text-muted pb-3">
                            {press.article_release_date}
                          </div>
                        </div>
                        <div className="press-details row align-items-center m-0">
                          <div className="col-lg-3 col-md-4 col-sm-5 col-3 ps-0">
                            <img src={press.press_logo} alt="press" />
                          </div>
                          <div className="col-lg-9 col-md-8 col-sm-7 col-9 ps-sm-0">
                            {press.press_name}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};
