import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { loginApi } from "../Redux/loginSlice";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, loading, error, auth } = useSelector((state) => state.login);

  if (localStorage.getItem("user") != null) {
      navigate("/dashboard");
  }
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = (e) => {
    e.preventDefault();
    let userCredential = { email, password };
    dispatch(loginApi(userCredential)).then((result) => {
      if (result.payload) {
        setEmail("");
        setPassword("");
        navigate("/dashboard");
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Login</title>
        <meta name="description" content="Login" />
      </Helmet>
      <>
        <div className="breadcrumbs">
          <div className="container">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Login</li>
            </ol>
          </div>
        </div>
        <section className="login pt-5 pb--60">
          <div className="container">
            <div className="login-user">
              <form
                action="/account/login"
                id="customer_login"
                onSubmit={login}
              >
                <h5 className="title mb-3 text-center">LOGIN</h5>
                <p className="mb-4">
                  If you have an account with us, please log in.
                </p>
                {error && (
                  <div
                    class="alert alert-warning alert-dismissible fade show"
                    role="alert"
                  >
                    <strong>Error</strong> {error}
                  </div>
                )}
                <div className="mb-3">
                  <input
                    value={email}
                    type="email"
                    name="email"
                    placeholder="Email"
                    id="CustomerEmail"
                    className="form-control"
                    autoCorrect="off"
                    autoCapitalize="off"
                    autofocus=""
                    fdprocessedid="ylebej"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <input
                    value={password}
                    type="password"
                    defaultValue=""
                    name="password"
                    placeholder="Password"
                    id="CustomerPassword"
                    className="form-control"
                    fdprocessedid="1kxvvf"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="login-button">
                  <Link to={"/forget-password"} className="mb-4">
                    Forgot your password?
                  </Link>
                  <input
                    type="submit"
                    className="btn btn-submit"
                    defaultValue="Sign In"
                  />
                </div>
                <div className="register my-4">
                  don't have an account?
                  <Link to={"/register"}> Create account</Link>
                </div>
              </form>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
