import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const orderListApi = createAsyncThunk("get/getOrderlist", async (id) => {
  return Axios({
    method: "get",
    url: API_URL + "user/order_list/" + id,
  }).then((response) => {
    return response.data.data.result;
  });
});

const orderListSlice = createSlice({
  name: "ordersList",
  initialState: {
    orders: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(orderListApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(orderListApi.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });
    builder.addCase(orderListApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default orderListSlice.reducer;
