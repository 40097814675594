import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";
//import { json } from "react-router-dom";

export const applyExhibitionsApi = createAsyncThunk(
  "post/applyExhibitions",
  async (postData) => {
    
    try {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      return Axios.post(API_URL + "apply_exhibitions", postData, config).then(
        (response) => {
          return response.data;
        }
      );
    } catch (error) {
      return error;
    }
  }
);

const applyExhibitionsSlice = createSlice({
  name: "login",
  initialState: {
    result: [],
    loading: false,
    error: "",
  },
  extraReducers: (builder) => {
    builder.addCase(applyExhibitionsApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(applyExhibitionsApi.fulfilled, (state, action) => {
      state.loading = false;
      state.result = action.payload;
      state.error = "";
    });
    builder.addCase(applyExhibitionsApi.rejected, (state, action) => {
      //console.log(action.payload.error);
      state.loading = false;
      state.result = [];
    });
  },
});
export default applyExhibitionsSlice.reducer;
