import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { orderDetailsApi } from "../Redux/orderDetailsSlice";
import { useParams } from "react-router-dom";

export const Confirmation = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { orderDetail } = useSelector((state) => state.orderDetails);

  useEffect(() => {
    dispatch(orderDetailsApi(params.slug));
  }, [params.slug]);

  return (
    <>
      <Helmet>
        <title>Confirmation</title>
        <meta name="description" content="Confirmation" />
      </Helmet>
      {
        orderDetail&& 
  
      <section className="confirmation pb--60 pt--60">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="booking-confirm">
                <div className="Conformation-top">
                  <div className="img text-center">
                    <img
                      src={  window.location.origin  + "/assets/images/check-mark.png"
                        } alt="confirmation"
                    />
                  </div>
                  <div className="head text-center">
                    Thank You. Your Order has been recevied!
                  </div>
                  <p className="text-center mb-5">
                    Your order has
                    been confirmed. Conformation mail will be send your register
                    email..
                  </p>
                </div>
                <div className="booking-details">
                  <div className="h5 mb-4">Booking Details</div>
                  <div className="row">
                    <div className="col-md-3 col-sm-6 col-6 mb-md-0 mb-2">
                      <div className="booking-id">
                        <div className="th">booking no</div>
                        <div className="td">{orderDetail.order_no}</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6 mb-md-0 mb-2">
                      <div className="booking-date">
                        <div className="th">Booking date</div>
                        <div className="td">{orderDetail.booking_date}</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="booking-amount">
                        <div className="th">Total Amount</div>
                        <div className="td">{orderDetail.total_amount}</div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-6">
                      <div className="Payment-method">
                        <div className="th">Payment Method</div>
                        <div className="td">{orderDetail.payment_type}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="order-summery">
                <div className="title h5 mb-4">
                  <b>Order Details</b>
                </div>
                <div className="row">
                {orderDetail.products && (
                  <div className="col-lg-6">
                      {orderDetail.products.map((list, key) => {
                        
                        return (
                    <div className="items">
                      <div className="order-item">
                        <div className="row align-items-center">
                          <div className="col-lg-2 col-sm-2 col-3 text-center">
                            <img
                              src={list.product_image}
                              alt={list.product_name}
                            />
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-7 col-6">
                            <h6 className="art-name">{list.product_name}</h6>
                            <span>{list.product_size},  {list.product_medium}, {list.product_orientation} </span>
                          </div>
                          <div className="col-lg-4 col-sm-3 col-3">
                            <div className="art-price">{list.product_price}</div>
                          </div>
                        </div>
                      </div>
                  
                    </div>
                        )
                        })}
                  </div>
                )}
                  <div className="col-lg-5">
                    <div className="price-info">
                      <div className="h5">Price Info</div>
                      {/* <div className="order-item py-2">
                        <span className="th">Chirstmas in Muscat x 2</span>
                        <span className="td">$ 1090.00</span>
                      </div>
                      <div className="order-item py-2">
                        <span className="th">In the Evening x 1</span>
                        <span className="td">$ 548.00</span>
                      </div> */}
                      <div className="sub-total py-2">
                        <span className="th">Sub Total</span>
                        <span className="td">{orderDetail.sub_total}</span>
                      </div>
                      <div className="shipping-fee py-2">
                        <span className="th">Shipping Fee</span>
                        <span className="td">{orderDetail.delivery_charge}</span>
                      </div>
                      <div className="tax-fee py-2">
                        <span className="th">Tax Fee</span>
                        <span className="td">{orderDetail.tax}</span>
                      </div>
                      <div className="total py-3">
                        <span className="th">Total</span>
                        <span className="td">{orderDetail.total_amount}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              {/* <Link to={"/"} className="btn btn-booking me-0 me-md-4">
                View Booking
              </Link> */}
              <Link to={"/"} className=" btn btn-booking">
                Continue Shopping
              </Link>
            </div>
          </div>
        </div>
      </section>
          }
    </>
  );
};
