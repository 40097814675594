import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const getArtist = createAsyncThunk(
  "get/getArtist",
  async (path, method = "get") => {
    return Axios({
      method: "get",
      url: API_URL + "artist_list",
    }).then((response) => {
       
      return response.data.data.result;
    });
  }
);

const artistSlice = createSlice({
  name: "artists",
  initialState: {
    artists: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(getArtist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getArtist.fulfilled, (state, action) => {
      state.loading = false;
      state.artists = action.payload;
    });
    builder.addCase(getArtist.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default artistSlice.reducer;

