import React from 'react'
import { Link } from "react-router-dom";
export const Footer = ({settings}) => {
  return (
    <><footer className="border-top">
    <div className="container">
      <div className="row">
        <div className="col-lg-4">
          <div className="py-3">
            <Link to={"/"}><img src={settings.image_name2} style={{ width: 180 }} /></Link>
          </div>
          <p className="">
            We are a family of creators – close knit and a little crazy –
            handcrafting goods at our workshop India. We make beautiful products
            from quality materials because believe each one is a representation of
            who we are close.
          </p>
        </div>
        <div className="col-lg-3">
          <h4 className="title py-3">Quick Links</h4>
          <ul className="list-unstyled">
            <li>
              <Link to="pages/about-us">About Us</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
            <li>
              <Link to="pages/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="pages/terms-and-conditions">Terms & Conditions</Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-2">
          <h4 className="title py-3">Resource</h4>
          <ul className="list-unstyled">
            <li>
            <Link to="/arts">Artworks</Link>
            </li>
            <li>
            <Link to="/artist">Artist</Link>
            </li>
            <li>
               <Link to="/exhibitions">Exhibtions</Link>
            </li>
            <li>
              <Link to="/press-release">Press Release</Link>
            </li>
            {/* <li>
              <Link to="/artist/login">Artist Login</Link>
            </li> */}
          </ul>
        </div>
        <div className="col-lg-3">
          <h4 className="title pt-3">Contact</h4>
          <ul className="list-unstyled">
            <li className="pb-3">
              <span className="">{settings.company_address}</span>
            </li>
            <li>
            <Link to={"tel:" +settings.company_email}>
                <i className="fa fa-phone " />
                <span className="ps-3">{settings.company_mobile}</span>
              </Link>
            </li>
            <li>
              <Link to={"mailto:" +settings.company_email}>
                <i className="fa-sharp fa-solid fa-envelope" />
                <span className="ps-3">{settings.company_email}</span>
              </Link>
            </li>
          </ul>
          <ul className="list-unstyled d-flex icon-size col-lg-7 social-media">
            <li>
              <Link to={settings.facbook} className="social-icon">
                <i className="fa-brands fa-facebook" />
              </Link>
            </li>
            <li>
              <Link to={settings.twitter} className="social-icon">
                <i className="fa-brands fa-twitter" />
              </Link>
            </li>
            <li>
              <Link to={settings.instrgram} className="social-icon">
                <i className="fa-brands fa-instagram" />
              </Link>
            </li>
            <li>
              <Link to={settings.google_plus} className="social-icon">
                <i className="fa-brands fa-whatsapp" />
              </Link>
            </li>
          
          </ul>
        </div>
      </div>
    </div>
  </footer>
  <div dangerouslySetInnerHTML={{__html: settings.footer_script}}></div>
  </>
  )
}
