import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { settingApi } from "../Redux/settingSlice";

export const ContactUs = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state.setting);
  useEffect(() => {
    // dispatch(getUsers());
    dispatch(settingApi());
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      <>
        <section className="contact pt--60 pb--60">
          <div className="container">
            <div className="head text-center">
              <div className="title h2 text-center mb-3">CONTACT US</div>
              <p>Need Any help? Feel free to aks us</p>
            </div>
            <div className="contact-info pb--60 pt--60">
              <div className="row">
                <div className="col-lg-5 col-md-5 contact-information">
                  {/* <h4 class=" pb-0"> Contact Info </h4> */}
                  <div className="col-lg-11  col-md-10 mt-md-5 mt-lg-0 ">
                    <div className="contact-address">
                      <h6 className=""> Address </h6>
                      <p className="pb-">
                      {setting.company_address}
                      </p>
                    </div>
                    <div className="contact-phone">
                      <h6 className=""> Phone </h6>
                      <div className="pb- mb-2">
                        <div className="mb-2">
                          <span className="pe-2">For user:</span>
                          <a href="tel:"> {setting.company_mobile} </a>
                        </div>
                        
                      </div>
                    </div>
                    <div className="contact-email">
                      <h6 className=""> E Mail </h6>
                      <div className="pb-">
                        <div className="mb-2">
                          <span className="pe-2">For user:</span>
                          <a href="tel:"> {setting.company_email} </a>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
                <div className="contact-form col-lg-7 col-md-7">
                  <form method="post" id="contactForm" action="#">
                    <p>
                      We are here to help! Please do not hesitate to email us
                      with any questions or concerns you may have
                    </p>
                    <div className="col-lg-12">
                      <div className="form-group  mb-3">
                        <label htmlFor="name" className="pb-1 h6">
                          
                          Name
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          id="email"
                          className="form-control"
                          autoCorrect="off"
                          autoCapitalize="off"
                          autofocus=""
                          fdprocessedid="ylebej"
                        />
                        <div className="validation-error d-none text-danger pt-2">
                          <p>This Field Is Required</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label htmlFor="phone " className="pb-1 h6">
                            
                            Phone
                          </label>
                          <input
                            type="tel"
                            className="form-control px-3"
                            id="phone"
                            name="phone"
                            placeholder="Enter Phone"
                          />
                          <div className="validation-error d-none text-danger pt-2">
                            <p>This Field Is Required</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group  mb-3">
                          <label htmlFor="email" className="pb-1 h6">
                            
                            E Mail
                          </label>
                          <input
                            type="email"
                            className="form-control px-3"
                            id="email"
                            name="email"
                            placeholder="Enter E-mail"
                          />
                          <div className="validation-error d-none text-danger pt-2">
                            <p>This Field Is Required</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label htmlFor="message" className="h6 pb-1">
                            
                            Message
                          </label>
                          <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Enter Your Message here...."
                            name="message"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group text-center pt-1">
                          <button
                            className="btn btn-submit"
                            data-effect="wave"
                            type="submit"
                          >
                            
                            Submit Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="map">
            <div className="container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d135691.36410832842!2d-0.10159865000000001!3d51.52864165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e1!3m2!1sen!2sin!4v1685181599365!5m2!1sen!2sin"
                width="100%"
                height={450}
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </section>
      </>
    </>
  );
};
