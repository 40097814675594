import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { exhibitionsApi } from "../Redux/exhibitionsSlice";
import { ExhibitionsComponent } from "./Component/ExhibitionsComponent";
import { ExhibitionsSkeletonComponent } from "./Component/ExhibitionsSkeletonComponent";

export const Exhibitions = () => {
  const dispatch = useDispatch();
  const { exhibitions, loading } = useSelector((state) => state.exhibition);
  useEffect(() => {
    dispatch(exhibitionsApi());
    // setBanners([results]);
  }, []);
  return (
    <>
      <Helmet>
        <title>Exhibitions</title>
        <meta name="description" content="Exhibitions" />
      </Helmet>
      <section className="exhibitions pt--60 pb--60">
        <div className="container">
          <h1 className="text-center mb-5 page-title">EXHIBITIONS</h1>
          {loading == true && (
            <div className="row">
              {Array(9)
                .fill(1)
                .map((card, key) => (
                  <ExhibitionsSkeletonComponent
                    key={key}
                  ></ExhibitionsSkeletonComponent>
                ))}
            </div>
          )}

          {loading == false && exhibitions.length ==0 && (
            <h3 className="text-center mt-5">No Exhibitions Found</h3>
          )}
          {exhibitions && (
            <div className="row">
              {exhibitions.map((exhibition, key) => {
                return (
                  <ExhibitionsComponent
                    exhibition={exhibition}
                    key={key}
                  ></ExhibitionsComponent>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
