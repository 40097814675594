import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const LeftMenu = ({ active }) => {
  const navigate = useNavigate();
  const handleLogout = (e) => {
    localStorage.removeItem("user");
    navigate("/");
  };
  return (
    <ul className="list-unstyled">
      <li className={active == 1 ? "active" : ""}>
        <Link to={"/dashboard"}>Dashboard</Link>
      </li>
      {/* <li className={active == 2 ? "active" : ""}>
                    <Link to={"/address"}>Address</Link>
                  </li> */}
      <li className={active == 2 ? "active" : ""}>
        <Link to={"/artist/add-arts"}>Add Paiting</Link>
      </li>
      <li className={active == 3 ? "active" : ""}>
        <Link to={"/artist/my-arts"}>My Arts</Link>
      </li>
        <li className={active == 4 ? "active" : ""}>
        <Link to={"/artist/my-orders"}>My Orders</Link>
      </li>
      <li className={active == 5 ? "active" : ""}>
        <Link to={"/artist/change-password"}>Change Password</Link>
      </li>
      <li onClick={handleLogout}>
        <Link to={"/logout"}>Logout</Link>
      </li>
    </ul>
  );
};
