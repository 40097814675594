import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const PressReleaseSkeletonComponent = (key) => {
  
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={key}>
    
        <div className="press-box">
          <div className="img">
          <Skeleton height={200} />
          </div>
          <div className="content">
            <p className=""> <Skeleton count={3}  /></p>
            <div className="date text-muted pb-3">
            <Skeleton  />
            </div>
          </div>
          <div className="press-details row align-items-center m-0">
            <div className="col-lg-3 col-md-4 col-sm-5 col-3 ps-0">
            <Skeleton height={56}  />
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7 col-9 ps-sm-0">
            <Skeleton  />
            </div>
          </div>
        </div>
  
    </div>
  );
};
