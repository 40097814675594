import { Route, Routes } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

export const Header = ({ settings }) => {
  const [isShow, invokeModal] = React.useState(false);
  const initModal = (mode) => {
    if (mode == 1) {
      return invokeModal(true);
    } else {
      return invokeModal(false);
    }
  };

  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  const { totalCart } = useSelector((state) => state.cartCount);
  const [search, setSearch] = useState("");
  const handleSearch = (e) => {
    e.preventDefault();
    invokeModal(false);
    navigate("/arts?search=" + search);
  };
  return (
    <>
      <header className="single-header">
        <div className="header-top">
          <div className="container">
            <div className="text-center">Welcome to Matti Sirvio Galleria</div>
          </div>
        </div>
        <div className=" navbar navbar-expand-lg d-block">
          <div className="header-middle">
            <div className="container">
              <div className="row align-items-center">
                <div className="navbar-toggler col-4">
                  <button
                    className=""
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                  >
                    <i className="fa fa-bars pull-right" />
                  </button>
                </div>
                <div className="header-middle col-lg-2 col-4 d-lg-block d-flex">
                  <div className="logo">
                    <Link to="/">
                      {settings.image_name && (
                        <img src={settings.image_name} alt="Logo" />
                      )}
                    </Link>
                  </div>
                </div>
                <div className="col-lg-2 col-4 d-lg-none d-block">
                  <ul className="navbar-nav justify-content-end header-end">
                    <li
                      className="nav-item cart d-block"
                      data-bs-toggle="modal"
                      data-bs-target="#search"
                    >
                      <span className="nav-link p-md-3 p-2" href="#">
                        <img
                          src={
                            window.location.origin + "/assets/images/search.png"
                          }
                          alt="search"
                        />
                      </span>
                    </li>
                    <li className="nav-item account d-lg-block ">
                      <Link
                        className="nav-link p-md-3 p-2"
                        to={auth == null ? "/login" : "/dashboard"}
                      >
                        <img
                          src={
                            window.location.origin + "/assets/images/user.png"
                          }
                          alt="user"
                        />
                      </Link>
                    </li>
                    <li
                      className="nav-item cart"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#cart"
                    >
                      <span className="nav-link p-md-3 p-2" href="#">
                        <img
                          src={
                            window.location.origin + "/assets/images/cart.png"
                          }
                          alt="cart"
                        />
                      </span>
                    </li>
                  </ul>
                </div>
                <div
                  className="header-bottom col-8 collapse navbar-collapse justify-content-center justify-content-center justify-content-center"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav justify-content-center">
                    <li className="nav-item">
                      <Link className="nav-link" to="/pages/about-us">
                        About Us
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/arts">
                        Artworks
                      </Link>
                    </li>
                    <li class="nav-item dropdown">
                      <span
                        className="nav-link dropdown-toogle"
                        href="javascript:void(0)"
                        data-bs-toogle="dropdown"
                      >
                        Services <i className="fa fa-angle-down "></i>
                      </span>
                      <ul className="dropdown-menu">
                        <li className="nav-link">
                          <Link className="nav-item" to="/services/artwork">
                            Artwork
                          </Link>
                        </li>
                        <li className="nav-link">
                          <Link className="nav-item" to="/services/exhibition">
                          Exhibition
                          </Link>
                        </li>
                        <li className="nav-link">
                          <Link className="nav-item" to="/services/solo-exhibition">
                          Solo Exhibition
                          </Link>
                        </li>
                        <li className="nav-link">
                          <Link className="nav-item" to="/services/art-consultancy">
                          
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/artist">
                        Artist Profile
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link className="nav-link" to="/exhibitions">
                        Exhibitions
                      </Link>
                    </li>

                    <Link className="nav-link" to="/press-release">
                      Press Release
                    </Link>
                  </ul>
                </div>
                <div className="col-lg-2 col-4 d-lg-block d-none">
                  <ul className="navbar-nav justify-content-end header-end">
                    <li className="nav-item cart d-block">
                      <span
                        className="nav-link p-md-3 p-2"
                        onClick={(e) => initModal(1)}
                      >
                        <img
                          src={
                            window.location.origin + "/assets/images/search.png"
                          }
                          alt="search"
                        />
                      </span>
                    </li>
                    <li className="nav-item account d-lg-block d-none">
                      <Link className="nav-link p-md-3 p-2" to={"/auth"}>
                        <img
                          src={
                            window.location.origin + "/assets/images/user.png"
                          }
                          alt="user"
                        />
                      </Link>
                    </li>
                    <li className="nav-item cart">
                      <Link className="nav-link p-md-3 p-2" to={"/cart"}>
                        <img
                          src={
                            window.location.origin + "/assets/images/cart.png"
                          }
                          alt="cart"
                        />
                        {totalCart > 0 && (
                          <div className="overlay">{totalCart}</div>
                        )}
                        {/* <span className="cartcount">0</span> */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <Modal show={isShow} className="search-modal">
        <Modal.Header>
          <form onSubmit={handleSearch} className="modal-title col-lg-7 m-auto">
            <div className="input-group">
              <input
                className=" form-control"
                placeholder="Search here.."
                onChange={(e) => setSearch(e.target.value)}
                defaultValue={search}
              />
              <button type="submit" className="input-group-text">
                Search
              </button>
            </div>
            <button
              type="button"
              className="btn-close"
              closeButton
              onClick={(e) => initModal(0)}
              aria-label="Close"
            ></button>

            {/* <button closeButton onClick={(e) => initModal(0)}>
              X
            </button> */}
          </form>
        </Modal.Header>
      </Modal>
    </>
  );
};
