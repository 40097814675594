import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const apiResults = createAsyncThunk(
  "get/getApiResults",
  async (path) => {
    return Axios({
      method: "get",
      url: API_URL + path,
    }).then((response) => {
      return response.data.data.result;
    });
  }
);

const apiSlice = createSlice({
  name: "api",
  initialState: {
    data: [],
    loading: false,
    status : "",
  },
  extraReducers: (builder) => {
    builder.addCase(apiResults.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(apiResults.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.status = "success";
    });
    builder.addCase(apiResults.rejected, (state) => {
      state.loading = false;
      state.status = "error";
    });
  },
});
export default apiSlice.reducer;

