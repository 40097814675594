import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const bannerApi = createAsyncThunk(
  "get/getBannerResults",
  async (path, method = "get") => {
    return Axios({
      method: "get",
      url: API_URL + "banner",
    }).then((response) => {
      return response.data.data.result;
    });
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    banners: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(bannerApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bannerApi.fulfilled, (state, action) => {
      state.loading = false;
      state.banners = action.payload;
    });
    builder.addCase(bannerApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default bannerSlice.reducer;

