import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const CartSkeletonComponent = () => {
  return (
    <div className="container">
      <h3 className="mb-5"><Skeleton></Skeleton></h3>
      <div className="row">
        <div className="col-lg-12">
          <div className="cart-header border-bottom d-md-block d-none">
            <div className="row">
              <div className="col-lg-8 col-md-7">
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="arts"><Skeleton></Skeleton></div>
                  </div>
                  <div className="col-lg-8 col-md-9">
                    <div className="art-info"><Skeleton></Skeleton></div>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-2">
                <div className="price"><Skeleton></Skeleton></div>
              </div>
              <div className="col-lg-2 col-md-2"></div>
            </div>
          </div>

          <div className="cart-body">
          {Array(2)
                .fill(1)
                .map((card, key) => (
                  
         
                <div className="cart-product" key={key}>
                  <div className="row align-items-center">
                    <div className="col-lg-8 col-md-7 mb-3">
                      <div className="row align-items-center">
                        <div className="col-lg-3 col-md-3 col-sm-2 mb-3">
                          <div className="art-image">
                                 <Skeleton></Skeleton>
                          </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-10">
                          <div className="art-info">
                            <div className="art-name">
                              <h5> <Skeleton></Skeleton></h5>
                            </div>
                            <div className="size">
                              <span className="size">
                              <Skeleton></Skeleton>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-2 col-md-2 col-sm-4 col-6 mb-3">
                      <div className="price">   <Skeleton></Skeleton></div>
                    </div>
                    <div className="col-lg-2 col-md-2 col-md-12 col-sm-4 mb-3 text-lg-center text-md-end delete">
                    <Skeleton></Skeleton>
                    </div>
                  </div>
                </div>
                    ))}
          </div>
        </div>
        <div className="cart-total">
          <div className="sub-total">
            <span><Skeleton></Skeleton></span>:
            <span className="total-price">  <Skeleton></Skeleton></span>
          </div>
          <p><Skeleton></Skeleton></p>
         
          <Skeleton></Skeleton>
        </div>
      </div>
    </div>
  );
};
