import { Route, Routes } from 'react-router-dom';
import { Product } from '../Pages/Product';
import { Home } from '../Pages/Home';
import { ProductDetail } from '../Pages/ProductDetail';
import { Artist } from '../Pages/Artist';
import { Login } from '../Pages/Login';
import { Checkout } from '../Pages/Checkout';
import { Pages } from '../Pages/Pages';
import { ArtistDetails } from '../Pages/ArtistDetails';
import { Exhibitions } from '../Pages/Exhibitions';
import { PressRelease } from '../Pages/PressRelease';
import { Dashboard } from '../Pages/User/Dashboard';
import { Logout } from '../Pages/User/Logout';
import { ExhibitionDetails } from '../Pages/ExhibitionDetails';
import { Confirmation } from '../Pages/Confirmation';
import { Cart } from '../Pages/Cart';
import { Register } from '../Pages/Register';
import { Orders } from '../Pages/User/Orders';
import { ChangePassword } from '../Pages/User/ChangePassword';
import { Auth } from '../Pages/User/Auth';
import { ContactUs } from '../Pages/ContactUs';
import { ArtistLogin } from '../Pages/Artist/ArtistLogin';
import { ArtistDashboard } from '../Pages/Artist/ArtistDashboard';
import { ArtistMyArts } from '../Pages/Artist/ArtistMyArts';
import { ArtistMyOrder } from '../Pages/Artist/ArtistMyOrder';
import { ArtistAddArts } from '../Pages/Artist/ArtistAddArts';

export const Routers = () => {
  
  return (
    <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/arts' element={<Product/>}></Route>
        <Route path='/arts/:slug'  element={<ProductDetail/>}></Route>
        <Route path='/artist'  element={<Artist></Artist>}></Route>
        <Route path='/artist/dashboard'  element={<ArtistDashboard/>}></Route>
        <Route path='/artist/my-arts'  element={<ArtistMyArts/>}></Route>
        <Route path='/artist/my-orders'  element={<ArtistMyOrder/>}></Route>
        <Route path='/artist/add-arts'  element={<ArtistAddArts/>}></Route>
        <Route path='/artist/:slug'  element={<ArtistDetails></ArtistDetails>}></Route>
        <Route path='/cart'  element={<Cart></Cart>}></Route>
        <Route path='/login'  element={<Login/>}></Route>
        <Route path='/register'  element={<Register/>}></Route>
        <Route path='/exhibitions'  element={<Exhibitions/>}></Route>
        <Route path='/exhibitions/:slug'  element={<ExhibitionDetails/>}></Route>
        <Route path='/press-release'  element={<PressRelease/>}></Route>
        <Route path='/checkout'  element={<Checkout/>}></Route>
        <Route path='/pages/:slug'  element={<Pages/>}></Route>
        <Route path='/services/:slug'  element={<Pages/>}></Route>
        <Route path='/contact-us'  element={<ContactUs/>}></Route>
        <Route path='/dashboard'  element={<Dashboard/>}></Route>
        <Route path='/orders'  element={<Orders/>}></Route>
        <Route path='/change-password'  element={<ChangePassword/>}></Route>
        <Route path='/auth'  element={<Auth/>}></Route>
        <Route path='/logout'  element={<Logout/>}></Route>
        <Route path='/confirmation/:slug'  element={<Confirmation/>}></Route>
        <Route path='/artist/login'  element={<ArtistLogin/>}></Route>
      
    </Routes>
  )
}
