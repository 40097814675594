import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const productsDetailApi = createAsyncThunk(
  "get/getProductsDetail",
  async (slug) => {
    try {
      return Axios.get(API_URL + "product_id/" + slug).then((response) => {
        return response.data.data;
      });
    } catch (error) {
      return error;
    }
  }
);

const productsDetailSlice = createSlice({
  name: "productsDetail",
  initialState: {
    productsDetail: [],
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(productsDetailApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(productsDetailApi.fulfilled, (state, action) => {
      state.loading = false;
      state.productsDetail = action.payload.result;
    });
    builder.addCase(productsDetailApi.rejected, (state) => {
      state.loading = false;
    });
  },
});
export default productsDetailSlice.reducer;
