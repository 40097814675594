import React, { useState, useEffect, uselayouteffect } from "react";
import { Link, useNavigate, Redirect, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileApi } from "./../../Redux/updateProfileSlice";
import { LeftMenu } from "./LeftMenu";
export const Dashboard = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.updateProfileData);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  const user = JSON.parse(auth);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  console.log(localStorage.getItem("user"));
  if (auth == null) {
    return <Navigate replace to="/" />;
  } else {
    const updateProfile = (e) => {
      setSuccess("");
      setError("");
      e.preventDefault();
      let userData = {
        first_name: firstName ? firstName : user.first_name,
        last_name: lastName ? lastName : user.last_name,
        mobile: mobile ? mobile : user.mobile_no,
        user_id: user.id,
      };
      dispatch(updateProfileApi(userData)).then((result) => {
        if (result.payload) {
          if (result.payload.error == 1) {
            setError(result.payload.data.message);
          } else {
            setSuccess("Update Succesfully");
            var a = result.payload[0]["result"];
            localStorage.setItem("user", JSON.stringify(a));
          }
        }
      });
    };

    return (
      <>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        <div className="breadcrumbs">
          <div className="container">
            <div className="title h1 pb-2">Dashbaord</div>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashbaord</li>
            </ol>
          </div>
        </div>
        <section className="account-details pt--60 pb--60">
          <div className="container">
            <h1 className="text-center mb-5">My Account</h1>
            <div className="row">
              <div className="col-lg-3">
                <div className="account-sidebar">
                  <LeftMenu active={1}></LeftMenu>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="account">
                  <div className="title">ACCOUNT INFO</div>
                  <div className="account-info">
                    <div className="pt-4">
                      {error && (
                        <div
                          class="alert alert-warning alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Error : </strong> {error}
                        </div>
                      )}

                      {success && (
                        <div
                          class="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Sucess : </strong> {success}
                        </div>
                      )}

                      <form onSubmit={updateProfile} id="account-info">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label htmlFor="first_name">First Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="first_name"
                              placeholder="First Name"
                              defaultValue={user.first_name}
                              fdprocessedid="sxamh"
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label htmlFor="last_name">Last Name</label>
                            <input
                              type="text"
                              className="form-control"
                              name="last_name"
                              placeholder="Last Name"
                              defaultValue={user.last_name}
                              fdprocessedid="g0za3s"
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label htmlFor="email">E Mail</label>
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              defaultValue={user.email}
                              readOnly=""
                              fdprocessedid="2jkxpd"
                              disabled
                            />
                          </div>
                          <div className="col-lg-12 mb-3">
                            <label htmlFor="Phone">Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              name="Phone"
                              placeholder="Phone Number"
                              defaultValue={user.mobile_no}
                              fdprocessedid="qwt0q"
                              onChange={(e) => setMobile(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="text-end col-md-12">
                          <button
                            type="submit"
                            className="btn-submit"
                            fdprocessedid="cp2iye"
                          >
                            Save Changes
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};
