import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { pressReleaseApi } from "../Redux/pressReleaseSlice";
import { PressReleaseComponent } from "./Component/PressReleaseComponent";
import { PressReleaseSkeletonComponent } from "./Component/PressReleaseSkeletonComponent";

export const PressRelease = () => {
  const dispatch = useDispatch();
  const { pressReleases, loading } = useSelector((state) => state.pressRelease);
  useEffect(() => {
    dispatch(pressReleaseApi());
  }, []);

  return (
    <>
      <Helmet>
        <title>Press Release</title>
        <meta name="description" content="Press Release" />
      </Helmet>

      <section className="press pt--60 pb--60">
        <div className="container">
          <h1 className="text-center title-element page-title">
            PRESS RELEASE
          </h1>

          {loading == true && (
            <div className="row">
              {Array(9)
                .fill(1)
                .map((card, key) => (
                  <PressReleaseSkeletonComponent
                    key={key}
                  ></PressReleaseSkeletonComponent>
                ))}
            </div>
          )}

          {loading == false && pressReleases.length ==0 && (
            <h3 className="text-center mt-5">No Press Release Found</h3>
          )}

          {pressReleases && (
            <div className="row">
              {pressReleases.map((press, key) => {
                return (
                  <PressReleaseComponent
                    pressRelease={press}
                    key={key}
                  ></PressReleaseComponent>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
