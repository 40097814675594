import React, { useState, useEffect, uselayouteffect } from "react";
import { Link, useNavigate, Redirect, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { updateProfileApi } from "../../Redux/updateProfileSlice";
import { LeftMenu } from "./LeftMenu";
export const ArtistAddArts = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.updateProfileData);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  const user = JSON.parse(auth);

  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [mobile, setMobile] = useState();
  const [success, setSuccess] = useState();
  const [error, setError] = useState();

  console.log(localStorage.getItem("user"));
  if (auth == null) {
    return <Navigate replace to="/" />;
  } else {
    const updateProfile = (e) => {
      setSuccess("");
      setError("");
      e.preventDefault();
      let userData = {
        first_name: firstName ? firstName : user.first_name,
        last_name: lastName ? lastName : user.last_name,
        mobile: mobile ? mobile : user.mobile_no,
        user_id: user.id,
      };
      dispatch(updateProfileApi(userData)).then((result) => {
        if (result.payload) {
          if (result.payload.error == 1) {
            setError(result.payload.data.message);
          } else {
            setSuccess("Update Succesfully");
            var a = result.payload[0]["result"];
            localStorage.setItem("user", JSON.stringify(a));
          }
        }
      });
    };

    return (
      <>
        <Helmet>
          <title>Dashboard</title>
          <meta name="description" content="Dashboard" />
        </Helmet>
        <div className="breadcrumbs">
          <div className="container">
            <div className="title h1 pb-2">Add Arts</div>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to={"/"}>Home</Link>
              </li>
              <li className="breadcrumb-item active">Add Arts</li>
            </ol>
          </div>
        </div>
        <section className="account-details pt--60 pb--60">
          <div className="container">
            <h1 className="text-center mb-5">Add Arts</h1>
            <div className="row">
              <div className="col-lg-3">
                <div className="account-sidebar">
                  <LeftMenu active={2}></LeftMenu>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="account">
                  <div className="title">Add Arts</div>
                  <div className="add-painting">

                  {error && (
                        <div
                          class="alert alert-warning alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Error : </strong> {error}
                        </div>
                      )}

                      {success && (
                        <div
                          class="alert alert-success alert-dismissible fade show"
                          role="alert"
                        >
                          <strong>Sucess : </strong> {success}
                        </div>
                      )}

                    <div className="pt-4">
                      <form action="" method="post" id="add-painting">
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="mb-1" htmlFor="art_pricture">
                              Art Picture
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              name="art_picture"
                              placeholder=""
                              defaultValue=""
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="mb-1" htmlFor="art_name">
                              Art Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="art_name"
                              placeholder="Art Name"
                              defaultValue=""
                              required
                            />
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="mb-1" htmlFor="art_name">
                              Art Code
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="art_code"
                              placeholder="Art Code"
                              defaultValue=""
                              required
                            />
                          </div>

                          
                          <div className="col-lg-6 mb-4">
                            <label className="mb-1" htmlFor="size">
                              Size
                            </label>
                            <select className="form-select" name="size">
                              <option className="" disabled="" selected="">
                                Select Size
                              </option>
                            </select>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <label className="mb-1" htmlFor="medium">
                              Medium
                            </label>
                            <select className="form-select" name="medium">
                              <option className="" disabled="" selected="">
                                Select Medium
                              </option>
                            </select>
                          </div>
                    
                          <div className="col-lg-6 mb-4">
                            <label className="mb-1" htmlFor="medium">
                              Medium
                            </label>
                            <select className="form-select" name="medium">
                              <option className="" disabled="" selected="">
                                Select Medium
                              </option>
                            </select>
                          </div>
                    
                         
                          <div className="col-lg-12 mb-4">
                            <label className="mb-1" htmlFor="price">
                              Art Description
                            </label>
                            <textarea
                              type="text"
                              rows={6}
                              className="form-control"
                              name="description"
                              placeholder="Art Description"
                              value=""
                              defaultValue={""}
                              required
                            />
                          </div>
                          <div className="col-lg-12 mb-4">
                            <label className="mb-1" htmlFor="price">
                              Art info
                            </label>
                            <textarea
                              type="text"
                              rows={6}
                              className="form-control"
                              name="description"
                              placeholder="Art info"
                              value=""
                              defaultValue={""}
                              required
                            />
                          </div>
                          
                   
                          

                        </div>
                        <div className="text-end col-md-12">
                          <a href="dashboard1.html" className="btn-cancel me-4">
                            Cancel
                          </a>
                          <button type="submit" className="btn-submit">
                            Add Painting
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
};
