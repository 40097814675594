import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { Routers } from "../Routers/Routers";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { settingApi } from "../Redux/settingSlice";
import CookieConsent from "react-cookie-consent";

export const Layout = () => {
  const { setting } = useSelector((state) => state.setting);
  const dispatch = useDispatch();
  useEffect(() => {
    // dispatch(getUsers());
    dispatch(settingApi());
  }, []);

  return (
    <>
      <Header settings={setting}></Header>
      <Routers></Routers>
      <Footer settings={setting}></Footer>
      <CookieConsent
        location="bottom"
        buttonText="Accpect"
        cookieName="mycookies"
        style={{ background: "#000" }}
        buttonStyle={{ color: "#FFF", fontSize: "13px" ,background :"#CCB48E" }}
        expires={150}
      >We use cookies on this website. They help us to remember you and how you use this website, 
      which improves your browsing experience. They are stored locally on your computer or mobile device. 
      Access the Cookies Notice  to see a complete list of these technologies and tell us whether they can be used on your device. For more information, please review our 
      <Link to={"/pages/privacy-policy"} style={{color: "#CCB48E"}}> Privacy Notice</Link>
      </CookieConsent>
    </>
  );
};
