import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { exhibitionsDetailsApi } from "../Redux/exhibitionsDetailsSlice";
import { exhibitionsApi } from "../Redux/exhibitionsSlice";
import { applyExhibitionsApi } from "../Redux/applyExhibitionsSlice";
import { Modal, Button } from "react-bootstrap";

import moment from "moment";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";
const shareUrl = window.location.href;

export const ExhibitionDetails = () => {
  const dispatch = useDispatch();
  const { exhibitionDetail } = useSelector((state) => state.exhibitionsDetails);
  const { exhibitions } = useSelector((state) => state.exhibition);
  const { result, loading } = useSelector((state) => state.applyExhibitions);

  const params = useParams();
  const [artistName, setArtistName] = useState("");
  const [artistEmail, setArtistEmail] = useState("");
  const [artistMobile, setArtistMobile] = useState("");
  const [artistDescription, setArtistDescription] = useState("");
  const [paintingUpload, setPaintingUpload] = useState("");
  const [paintingName, setPaintingName] = useState("");
  const [uploadPortfolio, setUploadPortfolio] = useState("");

  useEffect(() => {
    dispatch(exhibitionsDetailsApi(params.slug));
    dispatch(exhibitionsApi());
  }, [params.slug]);
  //console.log(exhibitions);

  const handleApply = (e) => {
    e.preventDefault();
    let userCredential = {
      artist_name: artistName,
      exhibition_id: exhibitionDetail.id,
      artist_description: artistDescription,
      artist_email: artistEmail,
      artist_mobile: artistMobile,
      painting_upload: paintingUpload,
      painting_file_name: paintingUpload.name,
      painting_name: paintingName,
      upload_portfolio: uploadPortfolio,
    };

    dispatch(applyExhibitionsApi(userCredential)).then((result) => {
      console.log(result);
      if (result.payload) {
        alert(result.payload.data.message);
        //setShow(false);
      }
    });

    return false;
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {exhibitionDetail && (
        <section className="exhibition-details pt--60 pb--60">
          <Helmet>
            <title>{exhibitionDetail.exhibition_title}</title>
            <meta name="description" content={exhibitionDetail.exhibition_short_description} />
            <meta
              property="og:description"
              content={exhibitionDetail.exhibition_short_description}
            />
            <meta
              property="og:title"
              content={exhibitionDetail.exhibition_title}
            />
            <meta
              property="og:description"
              content={exhibitionDetail.exhibition_short_description}
            />
            <meta
              property="og:image"
              content={exhibitionDetail.exhibition_image}
            />
          </Helmet>
          <div className="container">
            <div className="title">
              <h1 className=" page-title-2">
                {exhibitionDetail.exhibition_title}
              </h1>
              <div className="exhibition-date">
                {moment(exhibitionDetail.exhibition_start_date).format(
                  "DD MMM YYYY"
                )}
                -
                {moment(exhibitionDetail.exhibition_end_date).format(
                  "DD MMM YYYY"
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 about-exhibition">
                <div className="image pb-4">
                  <img
                    src={exhibitionDetail.exhibition_image}
                    alt={exhibitionDetail.exhibition_title}
                  />

                  {exhibitionDetail.images &&
                    exhibitionDetail.images.map((image, key) => {
                      return (
                        <div className="mt-3" key={key}>
                          <img
                            src={image.image_name}
                            alt={exhibitionDetail.exhibition_title}
                          />
                        </div>
                      );
                    })}
                </div>
                <section className="exhibition-fulldescription">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: exhibitionDetail.exhibition_short_description,
                    }}
                  ></div>

                  <div
                    dangerouslySetInnerHTML={{
                      __html: exhibitionDetail.exhibition_full_description,
                    }}
                  ></div>
                </section>
                <div className="share border-top border-bottom mt-4 py-2 text-center">
                  <div className="Demo__some-network">
                    <EmailShareButton
                      url={shareUrl}
                      subject={exhibitionDetail.exhibition_title}
                      body="body"
                      className="Demo__some-network__share-button"
                    >
                      <EmailIcon size={32} round />
                    </EmailShareButton>
                    <FacebookShareButton
                      url={shareUrl}
                      className="Demo__some-network__share-button"
                    >
                      &nbsp;
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    &nbsp;
                    <WhatsappShareButton
                      url={shareUrl}
                      title={exhibitionDetail.exhibition_title}
                      separator=":: "
                      className="Demo__some-network__share-button"
                    >
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                    &nbsp;
                    <TwitterShareButton
                      url={shareUrl}
                      title={exhibitionDetail.exhibition_title}
                      className="Demo__some-network__share-button"
                    >
                      <XIcon size={32} round />
                    </TwitterShareButton>
                  </div>
                </div>

                {exhibitionDetail.products &&  exhibitionDetail.products.length > 0 && (
                  <section className="arrivals  pb--60">
                    <h4 className="mb-4 mt-4">ARTS</h4>
                    <div className="row mb-4 filter">
                      {exhibitionDetail.products.map((product, key) => {
                        return (
                          <div className="col-lg-4 col-sm-4 col-6" key={key}>
                            <Link to={`/arts/${product.product_slug}`}>
                              <div className="arrival-box">
                                <div className="img">
                                  <img
                                    src={product.feature_image}
                                    alt={product.product_name}
                                  />
                                </div>
                                <div className="content">
                                  <p className="text-center">
                                    {product.product_name}
                                  </p>
                                  <div className="Price text-center">
                                    {product.product_price}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                )}
              </div>
              <div className="col-lg-4">
                <aside className="side-bar">
                  <div className="upcoming-exhibition mb-5">
                    <button
                      onClick={handleShow}
                      type="button"
                      className="btn btn-success btn-lg btn-apply"
                    >
                      Apply Exhibition
                    </button>
                  </div>

                  {exhibitions && (
                    <div className="upcoming-exhibition mb-5">
                      <div className="h5 border-bottom pb-3 mb-4">
                        Upcoming Exhibition
                      </div>

                      <ul className="">
                        {exhibitions.map((exhibi, key) => {
                          return (
                            <li key={key}>
                              <Link
                                to={"/exhibitions/" + exhibi.exhibition_slug}
                              >
                                {exhibi.exhibition_title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  )}
                </aside>
              </div>
            </div>
          </div>
        </section>
      )}
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={handleApply}>
          <Modal.Header closeButton>
            <Modal.Title>Apply Exhibition</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="artistname">
                    Artist Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="artistname"
                    placeholder="Artist Name"
                    onChange={(e) => setArtistName(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="artistname">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="artistemail"
                    placeholder="Artist Email"
                    onChange={(e) => setArtistEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="artistname">
                    Artist Mobile <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="artistmobile"
                    placeholder="Artist Mobile"
                    onChange={(e) => setArtistMobile(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-group mb-3">
              <label htmlFor="artistdescription">
                Artist Description <span className="text-danger">*</span>
              </label>
              <textarea
                className="form-control"
                id="artistdescription"
                rows={3}
                defaultValue={""}
                onChange={(e) => setArtistDescription(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="patingUpload">
                Painting Upload <span className="text-danger">*</span>
              </label>
              <input
                type="file"
                className="form-control"
                id="patingUpload"
                placeholder=" Painting Upload "
                onChange={(e) => setPaintingUpload(e.target.files[0])}
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="paintingName">
                Painting Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="paintingName "
                placeholder="Painting Name "
                onChange={(e) => setPaintingName(e.target.value)}
                required
              />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1">
                Upload Portfolio (if any)
              </label>
              <input
                type="file"
                className="form-control"
                id="exampleInputPassword1"
                placeholder=" "
                onChange={(e) => setUploadPortfolio(e.target.files[0])}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" variant="primary">
              Apply
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
