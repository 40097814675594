import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { API_URL } from "../Constant/Constant";

export const productsApi = createAsyncThunk(
  "get/getProducts",
  async (postData) => {
    try {
      return Axios.post(API_URL + "product_search", postData).then((response) => {
        return response.data.data;
      });
    } catch (error) {
      return error
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    totalProduct : 0,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(productsApi.pending, (state,action) => {
      if(action.meta.arg.page === 1){
        state.products =  [] ; 
        state.loading = true;
      }
    
    });
    builder.addCase(productsApi.fulfilled, (state, action) => {
      
      state.loading = false;
      state.products = [...state.products, ...action.payload.result] ;
      state.totalProduct = action.payload.total_product;
    });
    builder.addCase(productsApi.rejected, (state) => {
      state.loading = false;
      state.products =  [] ; 
    });
  },
});
export default productsSlice.reducer;

