import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { ArtistDetailsApi } from "../Redux/artistDetailsSlice";
import { productsApi } from "../Redux/productsSlice";

export const ArtistDetails = () => {
  const dispatch = useDispatch();
  const { artistDetails } = useSelector((state) => state.artistDetails);
  const { products } = useSelector((state) => state.products);
  const params = useParams();

  useEffect(() => {
    dispatch(ArtistDetailsApi(params.slug));
    dispatch(productsApi({ artist_slug: params.slug ,page: 1 }));
  }, [params.slug]);

  const artistDetail = artistDetails;

  return (
    <>
      <Helmet>
        <title>Artist</title>
        <meta name="description" content="Artworks" />
      </Helmet>
      {artistDetail && (
        <>
          <section className="artist-details pt--60 pb--60">
            <div className="container">
              <div className="artist-info">
                <div className="row  align-items-center">
                  <div className="col-lg-4">
                    <div className="artist-image">
                      <img src={artistDetail.profile_image} />
                    </div>
                  </div>
                  <div className="col-lg-7 artist-content">
                    <div className="artist-name">
                      {artistDetail.first_name} {artistDetail.last_name}
                    </div>
                    <div className="mb-3">
                      <div className="about-artist">
                        {artistDetail.description}
                      </div>
                    </div>
                    <div className="artist-location mb-3">
                      <i className="fa fa-location-dot pe-4" />
                      {artistDetail.location}
                    </div>
                    <div className="total-artworks mb-3">
                      <i className="fa-regular fa-file pe-4" />
                      Artworks - {products.length}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {products.length > 0 && (
            <section className="arrivals  pb--60">
              <div className="container">
                <h4 className="mb-4">
                  ARTWORKS By {artistDetail.first_name} {artistDetail.last_name}
                </h4>
                <div className="row mb-4 filter">
                  {/* <div className="col-md-3">
                <div className="sort-by">
                  <select className="form-select">
                    <option selected="" disabled="">
                      Sort By
                    </option>
                    <option value={1}>Name Ascending</option>
                    <option value={2}>Name Descending</option>
                    <option value={3}>Date Ascending</option>
                    <option value={4}>Date Descending</option>
                    <option value={5}>Price Ascending</option>
                    <option value={6}>Price Descending</option>
                  </select>
                </div>
              </div> */}
                  {/* <div className="col-md-3">
                <div className="sort-by">
                  <select className="form-select">
                    <option selected="" disabled="">
                      Category
                    </option>
                    <option value={1}>Painting</option>
                    <option value={2}>Photography</option>
                    <option value={3}>Sculpture</option>
                    <option value={4}>Drawing</option>
                  </select>
                </div>
              </div> */}
                </div>
                <div className="row">
                  {products.map((product, key) => {
                    return (
                      <div className="col-lg-3 col-sm-4 col-6" key={key}>
                        <Link to={`/arts/${product.product_slug}`}>
                          <div className="arrival-box">
                            <div className="img">
                              <img
                                src={product.feature_image}
                                alt={product.product_name}
                              />
                            </div>
                            <div className="content">
                              <p className="text-center">
                                {product.product_name}
                              </p>
                              <div className="Price text-center">
                                {product.product_price}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          )}
        </>
      )}
    </>
  );
};
