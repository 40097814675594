import {  useNavigate, Redirect, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export const Auth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);
  if (localStorage.getItem("user") == null) {
    return <Navigate replace to="/login" />;
  } else {
    return <Navigate replace to="/dashboard" />;
  }
};
