import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export const PressReleaseComponent = (pressRelase, key) => {
    const press =  pressRelase.pressRelease ;
  return (
    <div className="col-lg-3 col-md-4 col-sm-6 col-12" key={key}>
      <Link to={press.article_pdf} target="_blank">
        <div className="press-box">
          <div className="img">
            <img src={press.article_image} alt="news" />
          </div>
          <div className="content">
            <p className="">{press.article_title}</p>
            <div className="date text-muted pb-3">
              {press.article_release_date}
            </div>
          </div>
          <div className="press-details row align-items-center m-0">
            <div className="col-lg-3 col-md-4 col-sm-5 col-3 ps-0">
              <img src={press.press_logo} alt="press" />
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7 col-9 ps-sm-0">
              {press.press_name}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
