import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { getArtist } from "../Redux/artistSlice";
import { ArtistComponent } from "./Component/ArtistComponent";
import { ArtistkeletonComponent } from "./Component/ArtistkeletonComponent";

export const Artist = () => {
  const dispatch = useDispatch();
  const { artists, loading } = useSelector((state) => state.artist);
  useEffect(() => {
    dispatch(getArtist("artist"));
    // setBanners([results]);
  }, []);

  return (
    <>
      <Helmet>
        <title>Artist</title>
        <meta name="description" content="Artworks" />
      </Helmet>
      <section className="our-artist pt--60 pb--60">
        <div className="container">
          <div className="head">
            <h1 className="page-title text-center">OUR ARTIST</h1>
            <p>
              Our success is a result of our artist and building upon our artist
              expertise and creative style of art providing to our clients.
            </p>
          </div>
          {/* <div className="artist-search row">
            <form action="" method="get" className="row m-0">
        <div className="input-group">
          <input
            type="text"
            name="search"
            className="form-control"
            placeholder="Search by artist"
          />
          <span className="input-group-text">
            <i className="fa fa-search" />
          </span>
        </div>
      </form> 
          </div> */}

          {loading == true && (
            <div className="row">
              {Array(9)
                .fill(1)
                .map((card, key) => (
                  <ArtistkeletonComponent key={key}></ArtistkeletonComponent>
                ))}
            </div>
          )}

          {loading == false && artists.length ==0 && (
            <h3 className="text-center mt-5">No Artist Found</h3>
          )}
          {artists && (
            <div className="row">
              {artists.map((artist, key) => {
                return (
                  <ArtistComponent artist={artist} key={key}></ArtistComponent>
                );
              })}
            </div>
          )}
        </div>
      </section>
    </>
  );
};
