import React, { useState, useEffect, uselayouteffect } from "react";
import { Link, useNavigate, Redirect, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { orderListApi } from "./../../Redux/orderListSlice";
import { LeftMenu } from "./LeftMenu";
export const ArtistMyArts = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state.login);
  const user = JSON.parse(auth);
  const navigate = useNavigate();
  const { orders, loading } = useSelector((state) => state.ordersList);

  const [user_id, setUserId] = useState(user.id);

  useEffect(() => {
    dispatch(orderListApi(user_id));
    // setBanners([results]);
  }, []);

  return (
    <>
      <Helmet>
        <title>My Arts</title>
        <meta name="description" content="My Arts" />
      </Helmet>
      <div className="breadcrumbs">
        <div className="container">
          <div className="title h1 pb-2">My Arts</div>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item active">My Arts</li>
          </ol>
        </div>
      </div>
      <section className="account-details pt--60 pb--60">
        <div className="container">
          <h1 className="text-center mb-5">My Arts</h1>
          <div className="row">
            <div className="col-lg-3">
              <div className="account-sidebar">
                <LeftMenu active={3}></LeftMenu>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="account">
                <div className="title">My Arts</div>
                <div className="account-info">
                  <div className="pt-4">
                   

                    <div className="add-painting">
                      <div className="pt-4">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Id</th>
                              <th>Image</th>
                              <th>Art Name</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>
                                <img
                                  src="assets/images/artworks/Chirstmas in Muscat.jpg"
                                  alt="Chirstmas in Muscat"
                                  height="40px"
                                />
                              </td>
                              <td>Chirstmas in Muscat</td>
                              <td>Active</td>
                              <td>
                                <a
                                  href=""
                                  className="btn btn-success px-2 py-1"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>2</td>
                              <td>
                                <img
                                  src="assets/images/artworks/Transparency 2.jpg"
                                  alt="Chirstmas in Muscat"
                                  height="40px"
                                />
                              </td>
                              <td>Transparency 2</td>
                              <td>2</td>
                              <td>
                                <a
                                  href=""
                                  className="btn btn-success px-2 py-1"
                                >
                                  {" "}
                                  View
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>3</td>
                              <td>
                                <img
                                  src="assets/images/artworks/Evening in the Village.jpg"
                                  alt="Chirstmas in Muscat"
                                  height="40px"
                                />
                              </td>
                              <td>Evening in the Village</td>
                              <td>2</td>
                              <td>
                                <a
                                  href=""
                                  className="btn btn-success px-2 py-1"
                                >
                                  View
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
