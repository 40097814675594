import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { updatePasswordApi } from "./../../Redux/updatePasswordSlice";
import { LeftMenu } from "./LeftMenu";
export const ChangePassword = () => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.updatePassword);
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state.login);

  if (auth == null) {
    navigate("/");
  }

  const user = JSON.parse(auth);
  const [oldPassword, setOldPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState(user.mobile_no);
  const [user_id, setUserId] = useState(user.id);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const updatePassword = (e) => {
    e.preventDefault();
    let userData = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
      user_id: user_id,
    };
   // console.log(userData);
    dispatch(updatePasswordApi(userData)).then((result) => {
      if (result.payload) {
        if (result.payload.error == 1) {
            setSuccess("");
          setError(result.payload.data.message);
        } else {
            setError("");
             setSuccess(result.payload.data.message);
             setNewPassword("");
             setConfirmPassword("");
             setOldPassword('')
        }
      }
    });
  };
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>
      <div className="breadcrumbs">
        <div className="container">
          <div className="title h1 pb-2">Change Password</div>
          <ol className="breadcrumb m-0">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Change Password</li>
          </ol>
        </div>
      </div>
      <section className="account-details pt--60 pb--60">
        <div className="container">
          <h1 className="text-center mb-5">Change Password</h1>
          <div className="row">
            <div className="col-lg-3">
              <div className="account-sidebar">
                <LeftMenu active={4}></LeftMenu>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="account">
                <div className="title">CHANGE PASSWORD</div>
                <div className="password">
                  <div className="pt-4">
                    {error && (
                      <div
                        class="alert alert-warning alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Error : </strong> {error}
                      </div>
                    )}

                    {success && (
                      <div
                        class="alert alert-success alert-dismissible fade show"
                        role="alert"
                      >
                        <strong>Sucess : </strong> {success}
                      </div>
                    )}

                    <form onSubmit={updatePassword}>
                      <div className="">
                        <div className="col-md-6 mb-3">
                          <label htmlFor="old_password">Old Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="old_password"
                            placeholder="Old Password"
                            defaultValue=""
                            onChange={(e) => setOldPassword(e.target.value)}
                            value={oldPassword}
                            required
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="new_password">New Password</label>
                          <input
                            type="password"
                            className="form-control"
                            name="new_password"
                            placeholder="New Password"
                            defaultValue=""
                            onChange={(e) => setNewPassword(e.target.value)}
                            value={newPassword}
                            required
                          />
                        </div>
                        <div className="col-lg-6 mb-3">
                          <label htmlFor="Comform_password">
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="Comform_password"
                            placeholder="Re Enter New Password"
                            defaultValue=""
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            value={confirmPassword}
                            required
                          />
                        </div>
                        <div className="text-end col-md-6">
                          <button type="submit" className="btn-submit">
                            Change Password
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
