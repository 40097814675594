import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export const ProductSkeletonDetail = (artist, key) => {
  return (
    <section className="art">
      <div className="container">
        <div className="row mt-4">
          <div className="col-lg-7 art-pic">
            <Skeleton height={400}></Skeleton>
          </div>
          <div className="col-lg-5">
            <div className="art-details">
              <div className="row mob-mx-2">
                <div className="col-lg-6 col-6">
                  <Skeleton></Skeleton>
                </div>
                <div className="col-lg-6 col-6 text-right">
                  <Skeleton></Skeleton>
                </div>
              </div>
              <div className="mb-3"></div>
              <Skeleton class="name " height={32}></Skeleton>
              <div className="mb-2"></div>
              <Skeleton class="name" height={16}></Skeleton>
              <div className="mb-3"></div>
              <Skeleton class="name" height={24}></Skeleton>
              <div className="mb-3"></div>
              <Skeleton class="name" height={16}></Skeleton>
              <div className="mb-3"></div>
              <Skeleton class="name" height={16}></Skeleton>
              <div className="mb-3"></div>

              <Skeleton class="name" height={50}></Skeleton>
              <div className="mb-3"></div>
              <Skeleton class="name" height={16}></Skeleton>
              <div className="mb-2"></div>
              <Skeleton class="name" height={30}></Skeleton>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
